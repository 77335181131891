import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Scorpio.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
                Scorpio
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              
              
              <h4 className="mx-2 text-sm">/</h4>
              <h4 className="text-sm">Scorpio</h4>
            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Scorpio </h1>
                <h2 className="text-sm md:text-base ml-4">Oct 24 - Nov 21</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/scorpio/scorpio-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Scorpio Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>

              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/scorpio/scorpio-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Scorpio Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/scorpio/scorpio-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Scorpio Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/scorpio/scorpio-love");
                  }}>
                  <h5 className="font-semibold text-black">Scorpio Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/scorpio/scorpio-nature");
                  }}>
                  <h5 className="font-semibold text-black">Scorpio Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/scorpio/scorpio-man");
                  }}>
                  <h5 className="font-semibold text-black">Scorpio Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/scorpio/scorpio-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Scorpio Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/scorpio/scorpio-traits");
                  }}>
                  <h5 className="font-semibold text-black">Scorpio Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/scorpio/scorpio-facts");
                  }}>
                  <h5 className="font-semibold text-black">Scorpio Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <span className="my-4 mx-1 md:mx-0">
            <p className="font-bold text-orange-500 inline-block mr-2">Sanskrit/Vedic name of Scorpio:</p> Vrishschika
<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Cancer Meaning:</p>The Scorpion
<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Type:</p>Water-Fixed-Negative
<br/><br/>
The Scorpio is a fiery, unpredictable, and sometimes violent sign. The 8th Sign of the Zodiac, the Scorpio, loves a good fight and can give 'intensity' a run for its money (worth). The Scorpio is a fiery, unpredictable, and sometimes violent sign.
 <br/><br/>
The purpose of a Scorpion is to explore the mysteries of a person, find out who is genuinely a great person, and find out who is truly good or bad. The purpose of a Scorpion is to find out who is truly good or bad by examining the person who is the subject of their fascination. 
 <br/><br/>
The Scorpion is the wise people, the people with the power to turn the scales in an eye. The Scorpions are not for the faint of heart. They are very intense, compelling, and very passionate.
 <br/><br/>
 <p className="font-bold text-orange-500 inline-block mr-2">To know about Scorpio:</p>  The Scorpio is a warrior capable of heroism and exceptional bravery. The Scorpio has a fierce desire to perform and achieve great things and prove that they are worthy of the 'gifts.' 
The Scorpios are fiercely loyal and will always defend themselves and those they care about. The Scorpio is very imaginative and creative and can often create an entirely new world in only a few hours.
 <br/><br/>


Driven, dedicated, and loyal, they also are ambitious and security-loving. Scorpius individuals are knowledgeable, driven people who can contribute brilliantly to any project. They are quick learners who can adapt to any task and work well in any environment.<br/><br/>
They are willing to learn new skills and be given new tasks. They are good at what they do and like to help others. They are also very good at what they have already learned.<br/><br/>
Scorpio people are motivated to learn and are eager to contribute to whatever is most important to them.
 <br/><br/>
Yes, these enthusiastic individuals are those who don't like to quit, and their diligent efforts are to die for. Yet, this same 'never say no' attitude becomes unbearable when the Scorpio takes and treats every 'no' as an abuse. 
 <br/><br/>
 
On the contrary, if you know more Scorpions, they are adorable in gesture, lovely, dependant on their friends, and excellent lovers. 
 <br/><br/>
They care for their family more than their happiness; their happiness relies on their loved ones. The Scorpio is an ideal and generous lover and is considered a loving and generous soul.
<br/><br/>
Scorpions love to play, and their love is eternal, even though it may sometimes seem that they only want to manipulate and control. They will do anything to get what they want, no matter how much it hurts others. They are highly intuitive and will go to considerable lengths to get what they need. They are often possessive and will fight to the death to protect their possessions. Betrayal is the word, and expression Scorpio-borns hate and cannot take prettily. 
<br/><br/>
The object of the plot is to ensure Scorpio's downfall. Scorpio's' work' is to take revenge on those who have betrayed him.
<br/><br/>
That's the title of a book by a friend of mine. A Scorpio may say, but they must have an expression that can be seen in their words and actions.
<br/><br/>
Betrayal means the act of going behind the person or thing you are guarding. It is not a term of endearment or love.
<br/><br/>
If someone truly gets in Scorpios' way, they lose their temper and get revenge. In short, they need plenty of space. 
 <br/><br/>
All said and done, Scorpios are fearless beings and keep moving on as they are determined to succeed. We found that their aura is something else; their aura of mystery and secretiveness makes them akin to a magnet – someone to whom others will, invariably, be drawn.
<br/><br/>
            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
